
/**
 * Phoneix theme custom JS main file
 */

// Scroll down
// function scrollDown() {
//     const arrowFull = document.querySelector('.arrow-down-svg-full-width');
//     const arrowMobile = document.querySelector('.arrow-down-svg-mobile');

//     if(arrowFull && arrowMobile) {
//         arrowFull.addEventListener('click', function() {
//             window.scrollBy(0, 1200);
//         })
    
//         arrowMobile.addEventListener('click', function() {
//             window.scrollBy(0, 400);
//         })
//     }; 
// }
//scrollDown();

//quote slider

let quoteSwiper =  new Swiper(".swiper-quote", {
  slidesPerView: 1,
  loop: true,
  freeMode: false,
  autoplay: {
    delay: 5000, // Delay between transitions in milliseconds (5 seconds)
  },
  navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
  },
  pagination: {
  el: '.swiper-pagination',
},
});

// Carousel slider
let carouselSwiper =  new Swiper(".swiper-carousel", {
    slidesPerView: 1,
    loop: true,
    freeMode: false,
    autoplay: {
      delay: 5000, // Delay between transitions in milliseconds (5 seconds)
    },
    navigation: {
        nextEl: '.swiper-button-next',
    },
    pagination: {
    el: '.swiper-pagination',
  },
});

if (document.querySelector('.swiper-news')) {

  // News slider
  let newsSwiper =  new Swiper(".swiper-news", {
      slidesPerView: 3,
      spaceBetween: '10px',
      a11y: false,
      loop: true,
      navigation: {
          nextEl: document.querySelector('.news-swiper-next'),
          prevEl: document.querySelector('.news-swiper-prev')
      },
      autoplay: {
        delay: 5000, // Delay between transitions in milliseconds (5 seconds)
      },
      spaceBetween: 0,
      breakpoints: {
          0: {
              slidesPerView: 1,
              spaceBetween: '10px',

          },
          992: {
              slidesPerView: 2,
              spaceBetween: '10px',

            },
          1400: {
            slidesPerView: 3,
            spaceBetween: '10px',

          }
        },
  });
}

document.addEventListener('DOMContentLoaded', function() {
  var initiallyHidden = document.querySelector('.initially-hidden');
  var button = document.querySelector('.readmore'); // Assuming your button has the class 'readmore'

  // Function to toggle the visibility of the initially hidden content
  function toggleVisibility() {
      if (initiallyHidden.style.display === 'none' || initiallyHidden.style.display === '') {
          initiallyHidden.style.display = 'block'; // Show the content when hidden or not set
      } else {
          initiallyHidden.style.display = 'none'; // Hide the content when shown
      }
  }

  if (button) {
    // Add click event listener to the button
    button.addEventListener('click', function() {
        toggleVisibility(); // Call the toggleVisibility function when the button is clicked
    });
  }
});


 /**
   * Mobile Menu Trigger & Function
   */
 const burgerMenubtn = $("#burger");
 const mobileNav = $(".mobile-slide-menu");
 const closeButton = $(".mobile-slide-menu .close");

 // add aria-hidden="true" to all links in the mobile menu on load
  mobileNav.find('a').attr('aria-hidden', 'true');
  mobileNav.find('a').attr('tabindex', '-1');


  // add accesskey to all navigation links in #masthead and .mobile-slide-menu
  const navLinks = document.querySelectorAll('#masthead a, .mobile-slide-menu a');
  const uniqueUrls = new Set();
  let accessKeyIndex = 0;
  const baseUrl = window.location.origin; // Gets the base URL of the site
  
  navLinks.forEach((link) => {
    const href = link.getAttribute('href');

    if (!href) {
      return;
    }
    // Check if the link is internal
    if (href.startsWith(baseUrl) || href.startsWith('/')) {
      if (!uniqueUrls.has(href)) {
        uniqueUrls.add(href);
        link.setAttribute('accesskey', accessKeyIndex++);
      }
    }
  });

  // remove aria-hidden="true" from all links in the mobile menu when the menu is open
  

 burgerMenubtn.click(function () {
   if (mobileNav.hasClass("mobile_nav_active")) {
     mobileNav.removeClass("mobile_nav_active");
     burgerMenubtn.removeClass("active");
     mobileNav.find('a').attr('aria-hidden', 'true');
     mobileNav.find('a').attr('tabindex', '-1');
   } else {
     mobileNav.addClass("mobile_nav_active");
     burgerMenubtn.addClass("active");
      mobileNav.find('a').removeAttr('aria-hidden');
      mobileNav.find('a').attr('tabindex', '0');
   }
 });
 closeButton.click(function () {
   if (mobileNav.hasClass("mobile_nav_active")) {
     mobileNav.removeClass("mobile_nav_active");
     burgerMenubtn.removeClass("active");
      mobileNav.find('a').attr('aria-hidden', 'true');
      mobileNav.find('a').attr('tabindex', '-1');
   } else {
     mobileNav.addClass("mobile_nav_active");
     burgerMenubtn.addClass("active");
      mobileNav.find('a').removeAttr('aria-hidden');
      mobileNav.find('a').attr('tabindex', '0');
   }
 });


//Accordion
 jQuery(document).ready(function () {
  if ($(".accordion").length) {
    var allPanels = $(".accordion dd").hide();
    $(".accordion dt > a").click(function () {
      if ($(this).hasClass("active")) {
        $(this).parent().next().slideUp();
        $(this).removeClass("active");
      } else {
        $(".accordion")
        allPanels.slideUp();

        $(".accordion dt > a").removeClass("active");
        $(this).addClass("active");
        $(this).parent().next().slideDown();
        return false;
      }
    });
  }
});

$(document).ready(function() {

  //hide and show on stories
  $('.readmore').click(function(){
    $('.initially-hidden').show();
    $('.content').attr('class','content');
    console.log('Showing content');
    $(this).remove();
  });


  var windowHeight = $(window).height(); // Get the initial viewport height

  $('section').each(function() {
    var sectionTop = $(this).offset().top; // Get the top position of the section

    if (sectionTop > windowHeight) { // Check if the section is below the fold
      $(this).addClass('scroll-section'); // Add .scroll-section class
    }
  });

  // Function to check if a section is in view
  function checkInView() {
    $('.scroll-section').each(function() {
      var topOfElement = $(this).offset().top;
      var bottomOfElement = topOfElement + $(this).outerHeight();
      var bottomOfScreen = $(window).scrollTop() + $(window).innerHeight();
      var topOfScreen = $(window).scrollTop();

      if ((bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement)){
        $(this).addClass('visible');
      } else {
        $(this).removeClass('visible');
      }
    });
  }

  // Initial check in case some sections should be immediately visible
  checkInView();

  // Check on scroll
  $(window).scroll(function() {
    checkInView();
  });
});

/**
 * Animate numbers
 */
document.addEventListener("DOMContentLoaded", function () {
  const section = document.querySelector(".three-statistics");
  const start = document.querySelector(".three-statistics .start");
  const numbers = document.querySelectorAll(".animate_number");

  if (!section) {
    return;
  }

  if (!numbers) {
    return;
  }

  // Function to format numbers with prefix and suffix
  function formatNumber(value, prefix = '', suffix = '') {
    return `${prefix}${value}${suffix}`;
  }

  // Set initial numbers to 0 with their formatting
  numbers.forEach((number) => {
    const originalText = number.textContent;
    const endValue = parseInt(originalText.replace(/[^0-9]/g, ''), 10);
    const prefix = originalText.match(/^[^0-9]*/)[0]; // Extract prefix
    const suffix = originalText.match(/[^0-9]*$/)[0]; // Extract suffix
    number.dataset.endValue = endValue;
    number.dataset.prefix = prefix;
    number.dataset.suffix = suffix;
    number.textContent = formatNumber(0, prefix, suffix); // Set initial to 0
  });

  function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function animateValue(element, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      element.textContent = formatNumber(Math.floor(progress * (end - start) + start), element.dataset.prefix, element.dataset.suffix);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  function animateNumbers() {
    if (isInViewport(start)) {
      numbers.forEach((number) => {
        const endValue = parseInt(number.dataset.endValue, 10);
        animateValue(number, 0, endValue, 2000);
      });
      window.removeEventListener('scroll', animateNumbers);
    }
  }

  window.addEventListener('scroll', animateNumbers);
  animateNumbers(); // Check if already in view
});




// document.addEventListener('DOMContentLoaded', function() {
//   const statisticsSection = document.querySelector('.three-statistics');
  
//   if (statisticsSection) {
//     const statistics = statisticsSection.querySelectorAll('.statisic');

//     const observer = new IntersectionObserver((entries, observer) => {
//       entries.forEach(entry => {
//         if (entry.isIntersecting) {
//           const numberElement = entry.target.querySelector('h1');
//           const targetValue = parseFloat(numberElement.textContent.replace(/[^0-9.]/g, ''));
//           const hasPrefix = numberElement.textContent.includes('£');
//           const prefix = hasPrefix ? '£' : '';
//           const suffix = numberElement.textContent.replace(/[0-9.£]/g, '');
//           const hasBnSuffix = numberElement.textContent.includes('bn');

//           animateCounter(numberElement, targetValue, prefix, suffix, hasPrefix, hasBnSuffix, 4000);
//           observer.unobserve(entry.target);
//         }
//       });
//     }, { threshold: 0.5 });

//     statistics.forEach(statistic => {
//       observer.observe(statistic);
//     });
//   }
// });
// function animateCounter(element, targetValue, prefix, suffix, hasPrefix, hasBnSuffix, duration) {
//   const startValue = 0;
//   let increment = targetValue / (duration / 10);

//   if (hasBnSuffix) {
//     increment = 0.1;
//   }

//   let currentValue = startValue;

//   const timer = setInterval(() => {
//     currentValue += increment;
//     if (currentValue >= targetValue) {
//       clearInterval(timer);
//       element.textContent = prefix + targetValue.toFixed(hasBnSuffix ? 1 : 0) + (hasBnSuffix ? 'bn' : '');
//     } else {
//       if (hasPrefix) {
//         element.textContent = prefix + (hasBnSuffix ? currentValue.toFixed(1) + 'bn' : formatNumber(Math.floor(currentValue)));
//       } else {
//         element.textContent = formatNumber(Math.floor(currentValue)) + suffix;
//       }
//     }
//   }, hasBnSuffix ? duration / (targetValue * 10) : 10);
// }


// function formatNumber(number) {
//   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }



// Slider Stories
let swiperStories = new Swiper('.slider-stories__slider', {
  slidesPerView: 1,
  loop: false,
  navigation: {
    nextEl: '.slider-stories__nav--next',
    prevEl: '.slider-stories__nav--prev',
  },
  autoplay: {
    delay: 12000, // Delay between transitions in milliseconds (12 seconds)
  },
  pagination: {
    el: '.pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className}"></span>`;
    },

  },
});




// Custom pagination
const storiesPaginationElements = document.querySelectorAll('.slider-stories__slider-slide__pagination');

storiesPaginationElements.forEach(pagination => {
  const dots = pagination.querySelectorAll('.dot');

  dots.forEach(dot => {
    dot.addEventListener('click', () => {
      const indexInParent = Array.from(dots).indexOf(dot);
      swiperStories.slideTo(indexInParent);

      // Remove "active" class from all dots
      storiesPaginationElements.forEach(pagination => {
        pagination.querySelectorAll('.dot').forEach(dot => {
          dot.classList.remove('active');
        });
      });

      // Add "active" class to the clicked dot and dots at the same index in each pagination element
      storiesPaginationElements.forEach(pagination => {
        pagination.querySelectorAll('.dot')[indexInParent].classList.add('active');
      });
    });
  });
});

swiperStories.on('slideChange', function (e) {
        // Remove "active" class from all dots
        storiesPaginationElements.forEach(pagination => {
          pagination.querySelectorAll('.dot').forEach(dot => {
            dot.classList.remove('active');
          });
        });


             // Add "active" class to the clicked dot and dots at the same index in each pagination element
      storiesPaginationElements.forEach(pagination => {
        pagination.querySelectorAll('.dot')[swiperStories.activeIndex].classList.add('active');
      });
});


document.addEventListener('DOMContentLoaded', function () {
  // Team slider
  if (document.querySelector('.team-slider')) {

    console.log('Team slider on page');

    const swiper = new Swiper('.team-slider', {
      // Optional parameters
      loop: true,



      slidesPerView: 3,

      // Navigation arrows
      navigation: {
        nextEl: '.slider-team__nav-next',
        prevEl: '.slider-team__nav-prev',
      },  autoplay: {
        delay: 5000, // Delay between transitions in milliseconds (5 seconds)
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        // when window width is >= 640px
        850: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    });
  }
});

// Investment Team Slider
const swiper = new Swiper('.investment-team-slider__slider', {
  // Optional parameters
  loop: true,
  slidesPerView: 3,
  spaceBetween: 30,
  autoplay: {
    delay: 5000, // Delay between transitions in milliseconds (5 seconds)
  },
  // If we need pagination

  // Navigation arrows
  navigation: {
    nextEl: '.slider-investment-team__nav--next',
    prevEl: '.slider-investment-team__nav--prev',
  },

  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    // when window width is >= 480px
    768: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    // when window width is >= 640px
    850: {
      slidesPerView: 3,
      spaceBetween: 40
    }
  }

  // And if we need scrollbar

});

/**
 * Preloader
 */

document.addEventListener('DOMContentLoaded', function() {
  // Remove the initial fadein animation class

  // Fade in page on load
  const body = document.querySelector('.preloader');
  body.classList.remove('initial-fadeout');

  body.querySelector('svg').style.transition = 'opacity 0.3s';
  body.querySelector('svg').style.opacity = 0;
  

  setTimeout(function() {
    body.style.transition = 'opacity 1s';
    body.style.opacity = 0;

 
  }, 500);

  let targetLink = '';
  // Capture clicks on links
  document.querySelectorAll('a').forEach(link => {

    link.addEventListener('click', function(event) {
      if (!this.getAttribute('href')) return; // Ignore links without href attribute
      if (this.target === '_blank') return; // Ignore links with target="_blank"
      if (this.classList.contains('no-fade')) return; // Ignore links with the no-fade class
      if (this.getAttribute('href') == '#') return; // Ignore links with href="#"
      if (event.ctrlKey || event.metaKey) return;

      


      const url = new URL(this.href);
      const isInternalLink = url.hostname === window.location.hostname;
      const isHashLink = url.hash && url.pathname === window.location.pathname;

      if (isInternalLink && !isHashLink) {
        event.preventDefault(); // Prevent the default link behavior
        targetLink = this.href; // Store the link URL
        fadeOutAndNavigate();
      }
    });

  });

  // Function to handle fade out and navigation
  function fadeOutAndNavigate() {
    body.style.transition = 'opacity 0.5s';
    body.style.opacity = 1;
    // document.body.style.overflow = 'hidden'; // Hide the scrollbar

    setTimeout(function() {
      window.location.href = targetLink;
    }, 500); // Match this timeout with the fade out transition duration
  }

  // Fade out page on beforeunload event
  window.addEventListener('beforeunload', function(event) {
    body.style.transition = 'opacity 0.5s';
    body.style.opacity = 1;
    body.querySelector('svg').style.transition = 'opacity 0.3s';
    body.querySelector('svg').style.opacity = 1;
    // this.document.body.style.overflow = 'hidden'; // Hide the scrollbar
  });
  

  window.addEventListener('pageshow', function(event) {
    if (event.persisted) {
      body.style.transition = 'opacity 1s';
      body.style.opacity = 0;
    } 
  });
});


const whatWeDoEls = document.querySelectorAll('section.what-we-do');

if (whatWeDoEls) {

  whatWeDoEls.forEach(whatWeDoEl => {
    const repeaterEls = whatWeDoEl.querySelectorAll('.repeater');
    const repeaterTextEls = whatWeDoEl.querySelectorAll('.repeater-text');

    repeaterEls.forEach(repeaterEl => { 
      repeaterEl.addEventListener('click', () => {  
        removeAllActiveRepeaterEls(repeaterEls);
        repeaterEl.classList.add('active');

        const index = Array.from(repeaterEls).indexOf(repeaterEl);

        removeAllActiveRepeaterTextEls(repeaterTextEls);

        repeaterTextEls[index].classList.add('active');

      });

      repeaterEl.addEventListener('mouseover', () => {
        removeAllActiveRepeaterEls(repeaterEls);
        repeaterEl.classList.add('active');

        const index = Array.from(repeaterEls).indexOf(repeaterEl);

        removeAllActiveRepeaterTextEls(repeaterTextEls);

        repeaterTextEls[index].classList.add('active');
      });


    });
  });

  function removeAllActiveRepeaterEls( repeaterEls ) {
    repeaterEls.forEach(repeaterEl => {
      repeaterEl.classList.remove('active');
    });
  }

  function removeAllActiveRepeaterTextEls( repeaterTextEls ) {
    repeaterTextEls.forEach(repeaterTextEl => {
      repeaterTextEl.classList.remove('active');
    });
  }
}